import axios from 'axios';

export const utils = {
    getAcsData: async () => {
        const searchParams = new URLSearchParams(document.location.search);
        const requestPayload = JSON.stringify({ token: searchParams.get('token') });

        try {
            let response = await axios({
                url: 'https://egusymt4qj.execute-api.eu-central-1.amazonaws.com/getcalldata?timestamp=' + new Date().getTime(),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: requestPayload
            })

            if (response.data) {
                return response.data;
            }
        } catch (error) {
            if(error.response && error.response.data)
                return error.response.data;
            
            else
                console.log("Axios error", error);
        }
    },

    acsError: (error) => {
        switch(error) {
            case "AccessToken expired":
                return "Das Token ist nicht mehr gültig."                    
                break;
            default:
                return null
        }
    },

    getApiError: (errorMsg) => {
        const text = {
            "malformed token": "Das Token ist ungültig.",
            "auth error": "Die Überprüfung des Tokens ist fehlgeschlagen.",
            "database error": "Die Überprüfung des Tokens ist fehlgeschlagen.",
            "server error": "Die Überprüfung des Tokens ist fehlgeschlagen.",
            "no token found": "Zur Authentifizierung ist ein Token erforderlich."
        }

        if(text[errorMsg])
            return text[errorMsg]

        return errorMsg
    }
}