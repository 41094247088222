import { useEffect, useState, lazy } from 'react';
import { useInterval } from 'react-interval-hook';

import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { 
  FluentThemeProvider, 
  LocalizationProvider, 
  DEFAULT_COMPONENT_ICONS, 
  COMPONENT_LOCALE_DE_DE,
  CallClientProvider,
  CallAgentProvider,
  CallProvider,
  createStatefulCallClient,
  useTheme
} from '@azure/communication-react';

import { Text } from '@fluentui/react';

import { initializeIcons, registerIcons } from '@fluentui/react';

import { utils } from "./Utils";
//import { findByLabelText } from '@testing-library/react';

const Loading  = lazy(() => import('./Loading'));
const ErrorPage = lazy(() => import('./ErrorPage'));
const CallingComponents = lazy(() => import('./CallComponentsStateful'));

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

function App() {
  const theme = useTheme();

  const [azureIdentity, setAzureIdentity] = useState();
  const [azureCredential, setAzureCredential] = useState();
  const [displayName, setDisplayName] = useState();
  const [meeting, setMeeting] = useState();
  const [apiError, setApiError] = useState();

  const [statefulCallClient, setStatefulCallClient] = useState();
  const [callAgent, setCallAgent] = useState();
  const [call, setCall] = useState();

  const [permissionMic, setPermissionMic] = useState();
  const [permissionCam, setPermissionCam] = useState();

  // run only once on pageload
  useEffect(() => {
    // Setup global error handling for Unhandled Promise Rejections
    window.addEventListener('unhandledrejection', (event) => {
      if(utils.acsError(event.reason.message))         setApiError(utils.acsError(event.reason.message));
      else console.log("Error",event.reason.message)

      event.preventDefault();
    });

    // get data from api
    utils.getAcsData().then((e) => {
      if(e.user)                            setAzureIdentity(e.user)
      if(e.token)                           setAzureCredential(new AzureCommunicationTokenCredential(e.token))
      if(e.username)                        setDisplayName(e.username)
      if(e.locator)                         setMeeting(e.locator)
      if(e.errorMessage)                    setApiError(utils.getApiError(e.errorMessage))
    })

    // get camera and mic permission state
    try {
      navigator.permissions
        .query({ name: "microphone" })
        .then((permissionStatus) => {
          setPermissionMic(permissionStatus.state)
          permissionStatus.onchange = () => {
            setPermissionMic(permissionStatus.state)
          };
        });

      navigator.permissions
        .query({ name: "camera" })
        .then((permissionStatus) => {
          setPermissionCam(permissionStatus.state)
          permissionStatus.onchange = () => {
            setPermissionCam(permissionStatus.state)
          };
        });
    } catch (e) {
      console.log(e);
    }

    // get user media for firefox camera activation
    async function getMedia() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      } catch (e) {
        console.log(e);
      }
    }

    getMedia();
  }, []);

  useEffect(() => {
    if(azureIdentity && azureIdentity.communicationUserId) {
      setStatefulCallClient(createStatefulCallClient({ userId: azureIdentity }));
    }
  }, [azureIdentity]);

  useEffect(() => {
    if (callAgent === undefined && statefulCallClient && azureCredential && displayName) {
      const createCallAgent = async () => {
        setCallAgent(await statefulCallClient.createCallAgent(azureCredential, { displayName: displayName }));
      };
      createCallAgent();
    }
  }, [callAgent, statefulCallClient, azureCredential, displayName]);

  useEffect(() => {
    if (callAgent !== undefined && meeting) {
      setCall(callAgent.join({ meetingLink: meeting, callOptions: getCallOptions(statefulCallClient) }))

      if(callAgent) {
        callAgent.on('callsUpdated', async (args) => {
          console.log(`CallAgent call changed`, args);
        });

        callAgent.on('connectionStateChanged', async (args) => {
          console.log(`Connection state changed`, args); 
        });
      }
    }
  }, [callAgent, meeting]);

  useEffect(() => {
      if(call)
        call.on('stateChanged', async () => {
          if(call.state === 'Disconnected') {
            setCall(null)
          }
        });
  }, [call]);

  /*
  useInterval(() => {
    if(!meeting && jwt) {
      utils.retryAuth(jwt).then((e) => {
        if(e.meeting)                       setMeeting(e.meeting)
        if(e.jwt)                           setJwt(e.jwt)
      })
    }
  }, 10000); // run every 10 seconds
  */

  if(apiError)
    return(
      <FluentThemeProvider>
        <ErrorPage label={apiError}></ErrorPage>
      </FluentThemeProvider>
    );

  return (
    <FluentThemeProvider>
      <LocalizationProvider locale={COMPONENT_LOCALE_DE_DE}>
        {permissionMic !== 'denied' && permissionCam === 'denied' && (
          <div style={bannerStyles}>
            <div style={iconContainerStyles(theme)}>
              <div style={iconStyles}>
                <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13 6.5A2.5 2.5 0 0 0 10.5 4h-6A2.5 2.5 0 0 0 2 6.5v7A2.5 2.5 0 0 0 4.5 16h4.7A5.5 5.5 0 0 1 13 9.2V6.5Zm5-.3v4.06a5.48 5.48 0 0 0-4-1.24V7.93l2.77-2.31a.75.75 0 0 1 1.23.57Zm-8 8.3a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0Zm1 0a3.5 3.5 0 0 1 5.6-2.8l-4.9 4.9a3.48 3.48 0 0 1-.7-2.1Zm3.5 3.5c-.79 0-1.51-.26-2.1-.7l4.9-4.9a3.5 3.5 0 0 1-2.8 5.6Z" fill="currentColor"></path></svg>
              </div>
            </div>
            <div>
                <Text styles={headingStyles} variant="large">Zugriff auf die Kamera verweigert</Text>
                <Text variant="medium">Bitte gestatten Sie dieser Website den Zugriff auf Ihre Kamera um diesen Dienst zu nutzen.</Text>
            </div>
          </div>
        )}

        {permissionMic === 'denied' && permissionCam !== 'denied' && (
          <div style={bannerStyles}>
            <div style={iconContainerStyles(theme)}>
              <div style={iconStyles}>
                <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.2 13A5.51 5.51 0 0 1 12 9.6V5a3 3 0 1 0-6 0v5a3 3 0 0 0 3.2 3Zm.3 4.5v-.7c-.32-.7-.5-1.48-.5-2.3A4.5 4.5 0 0 1 4.5 10a.5.5 0 0 0-1 0 5.5 5.5 0 0 0 5 5.48v2.02a.5.5 0 0 0 1 0Zm5 1.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-8a3.5 3.5 0 0 0-2.8 5.6l4.9-4.9a3.48 3.48 0 0 0-2.1-.7Zm-2.1 6.3a3.5 3.5 0 0 0 4.9-4.9l-4.9 4.9Z" fill="currentColor"></path></svg>
              </div>
            </div>
            <div>
                <Text styles={headingStyles} variant="large">Zugriff auf das Mikrofon verweigert</Text>
                <Text variant="medium">Bitte gestatten Sie dieser Webiste den Zugriff auf Ihr Mikrofon um diesen Dienst nutzen zu können.</Text>
            </div>
          </div>
        )}

        {permissionMic === 'denied' && permissionCam === 'denied' && (
          <div style={bannerStyles}>
            <div style={iconContainerStyles(theme)}>
              <div style={iconStyles}>
                <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.2 13A5.51 5.51 0 0 1 12 9.6V5a3 3 0 1 0-6 0v5a3 3 0 0 0 3.2 3Zm.3 4.5v-.7c-.32-.7-.5-1.48-.5-2.3A4.5 4.5 0 0 1 4.5 10a.5.5 0 0 0-1 0 5.5 5.5 0 0 0 5 5.48v2.02a.5.5 0 0 0 1 0Zm5 1.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-8a3.5 3.5 0 0 0-2.8 5.6l4.9-4.9a3.48 3.48 0 0 0-2.1-.7Zm-2.1 6.3a3.5 3.5 0 0 0 4.9-4.9l-4.9 4.9Z" fill="currentColor"></path></svg>
              </div>
            </div>
            <div style={iconContainerStyles(theme)}>
              <div style={iconStyles}>
                <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13 6.5A2.5 2.5 0 0 0 10.5 4h-6A2.5 2.5 0 0 0 2 6.5v7A2.5 2.5 0 0 0 4.5 16h4.7A5.5 5.5 0 0 1 13 9.2V6.5Zm5-.3v4.06a5.48 5.48 0 0 0-4-1.24V7.93l2.77-2.31a.75.75 0 0 1 1.23.57Zm-8 8.3a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0Zm1 0a3.5 3.5 0 0 1 5.6-2.8l-4.9 4.9a3.48 3.48 0 0 1-.7-2.1Zm3.5 3.5c-.79 0-1.51-.26-2.1-.7l4.9-4.9a3.5 3.5 0 0 1-2.8 5.6Z" fill="currentColor"></path></svg>
              </div>
            </div>
            <div>
                <Text styles={headingStyles} variant="large">Zugriff auf das Mikrofon und die Kamera verweigert</Text>
                <Text variant="medium">Bitte gestatten Sie dieser Webiste den Zugriff auf Ihr Mikrofon und Ihre Kamera um diesen Dienst nutzen zu können.</Text>
            </div>
          </div>
        )}

        {!callAgent && ( 
          <Loading label="Ihre Sitzung wird vorbereitet" />
        )}
        
        {callAgent && !meeting && ( 
          <ErrorPage label="Die Verbindung konnte nicht aufgebaut werden."></ErrorPage>
        )}

        {statefulCallClient && ( 
          <CallClientProvider callClient={statefulCallClient}>
            {callAgent && (
              <CallAgentProvider callAgent={callAgent}>
                { call && (
                  <CallProvider call={call}>
                    <CallingComponents />
                  </CallProvider>
                )}
              </CallAgentProvider>
            )}
          </CallClientProvider>
        )}
      </LocalizationProvider>
    </FluentThemeProvider>
  );
}

async function getCallOptions(client) {
  /* Use the DeviceManager to request for permissions to access the camera and microphone. */
  //const response = await (await client.getDeviceManager()).askDevicePermission({ audio: true, video: true });
  //console.log("Media Access Permission", response)

  //const deviceManager = await client.getDeviceManager()

  //const cameras = await deviceManager.getCameras();
  //const camera = cameras[0];
  //const localCameraStream = { source: camera, mediaStreamType: 'Video' };

  let callOptions = {
    /*videoOptions: {
      localVideoStreams: localCameraStream
    },*/
    audioOptions: {
        muted: false
    }
  };

  return callOptions;
}

const bannerStyles = {
  position: 'absolute',
  left: '0px',
  right: '0px',
  top: '0px',
  background: 'rgb(255,255,255)',
  padding: '1em 2em',
  zIndex: 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1em',
}

const headingStyles = {
  root: {
    fontWeight: '600',
    lineHeight: '2rem',
    display: 'block',
  }
};

const iconStyles = {
    transform: 'scale(2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '0.2rem',
    color: 'rgb(0, 120, 212)',
    zIndex: 1
};

const iconContainerStyles = (theme) => ({
    padding: '1.75rem',
    borderRadius: '50%',
    display: 'inline-block',
    background: theme.palette?.themeLighterAlt
});

export default App;
